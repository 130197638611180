<template>
  <div>
    <el-button plain @click="getRecord()">{{ title }}</el-button>
    <el-dialog
      title="发放记录"
      :visible.sync="recordDialog"
      :close-on-click-modal="false"
      center
      width="60%"
    >
      <el-table
        :data="tableData"
        border
        :header-cell-style="{
          background: '#FAFAFA',
          color: '#000000',
        }"
      >
        <el-table-column
          label="时间"
          align="center"
          prop="addDt"
        ></el-table-column>
        <el-table-column
          label="操作人"
          align="center"
          prop="optName"
        ></el-table-column>
        <el-table-column
        v-if="type ==1"
          label="发放积分数"
          align="center"
          prop="point"
        ></el-table-column>
        <el-table-column
         v-if="type ==2"
          label="发放卡券"
          align="center"
          prop="cardName"
        ></el-table-column>
        <el-table-column
          label="发放事由"
          align="center"
          prop="giveReason"
        ></el-table-column>
        <el-table-column label="用户数" align="center" prop="memberCount">
          <template slot-scope="scope">
            <span @click="getUserDetail(scope.row)" class="userCount">{{
              scope.row.memberCount
            }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-footer">
        <el-pagination
          layout="total,sizes, prev, pager, next,jumper"
          background
          :total="total"
          :page-pageSize="queryParams.pageSize"
          @pageSize-change="onSizeChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="currentPage"
          @current-change="onCurrentPage"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <el-dialog
      title="用户详情"
      :visible.sync="userDialog"
      :close-on-click-modal="false"
      center
      width="60%"
    >
      <el-input
        v-model="detailParams.kw"
        placeholder="请输入用户昵称/手机号搜索"
        @input="kwChange"
        clearable
        class="mr15"
      />
      <div class="tag_box">
        <div class="tag_item" v-for="(item, index) in userList" :key="index">
          <div class="tag">
            {{ item.nickName+' | '+item.mobile }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { sendRecord, sendRecordDetail } from "@/api/card/card";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    type: {
      type:Number,
      default:1//1积分记录 2 卡券记录
    }
  },
  data() {
    return {
      recordDialog: false,
      userDialog: false,
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [
        {
          time: "2024-04-01 12:00:00",
          name: "张三",
          type: "会员卡",
          number: "100",
        },
        {
          time: "2024-04-01 12:00:00",
          name: "张三",
          type: "会员卡",
          number: "100",
        },
      ],
      queryParams: {
        page: 1,
        pageSize: 10,
        kw: "",
      },
      detailParams: {
        giveId: "",
        kw: "",
      },
      userList: [],
    };
  },
  methods: {
    // 点击发放记录，获取表格列表
    getRecord() {
      this.recordDialog = true;
      sendRecord(this.queryParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.queryParams.page = page;
      this.getRecord();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.queryParams.pageSize = pageSize;
      this.getRecord();
    },
     // 点击用户数量
     getUserDetail(row) {
      this.userDialog = true;
      this.detailParams.giveId = row.giveId;
      this.getUserList()
    },
    // 搜索发放用户
    kwChange: _.debounce(function () {
      this.getUserList();
    }, 500),
   
    // 获取用户列表
    getUserList() {
      sendRecordDetail(this.detailParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.userList = res.data.list;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.userCount {
  color: blue;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.tag_box {
  padding: 0.15rem 0;
  height: calc(100% - 150px);
  overflow-y: auto;
  margin-top: 0.15rem;
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;

  .tag_item {
    position: relative;
    height: 36px;
    margin-bottom: 20px;
    margin-right: 22px;

    .tag {
      padding: 0 20px;
      height: 100%;
      line-height: 36px;
      text-align: center;
      background: rgba(153, 153, 153, 0.08);
      border-radius: 4px;

      font-weight: 400;
      font-pagesize: 14px;
      color: #333333;
    }
  }
}
</style>
